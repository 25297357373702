// extracted by mini-css-extract-plugin
export var center = "logo-module--center--2Zgt6";
export var fullwidth = "logo-module--fullwidth--29j2h";
export var svg = "logo-module--svg--2gglT";
export var l = "logo-module--l--1tRDh";
export var fadein = "logo-module--fadein--cdj2m";
export var i = "logo-module--i--3o0Ed";
export var l2 = "logo-module--l2--v6Ul6";
export var s = "logo-module--s--32tBz";
export var c = "logo-module--c--acon4";
export var h = "logo-module--h--2myz0";
export var k = "logo-module--k--1pfg9";