import React from 'react'
import { Helmet } from 'react-helmet'
import '../styles/index.scss';
import ViewModule from '../components/viewModule'
import ImageWrapper from '../components/imageWrapper'
import Spacer from '../components/spacer'
import Nav from '../components/nav'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import { graphql} from "gatsby"
import Logo from '../components/logo';
import Header from '../components/header';
import * as styles from "../components/imageWrapper.module.scss"
import Img from "gatsby-image";
import { motion } from "framer-motion"


function Index({data}) {



  return (
    <ParallaxProvider>
      <motion.main
          initial={{ opacity: 0 }}
          animate={{ opacity: 1}}
          exit={{ opacity: 0}}
          transition={{
            type: "spring",
            mass: 2,
            stiffness: 20,
            duration: 8,
          }}
        >
        <Helmet>
          <title>Lilschk Photography</title>
        </Helmet>
        <div className="fixed foreground">
            <Header></Header>
        </div>
        <div className="fixed">
          <Logo></Logo>
        </div>
        <div className="navigation">
          <Nav></Nav>
        </div>
        <div style={{overflow:"hidden;"}}>
          {data.allimages.edges.map( (image,i)  => 
            { if(i%2 === 0)
              return <Parallax speed={-10}>          <Spacer height={"800"}></Spacer>
                          <div className={styles.div1}>
                              <Img className={styles.image} key={i} fluid={image.node.childImageSharp.fluid}></Img>
                          </div>
                      </Parallax>
              return <Parallax speed={20}>
                          <div className={styles.div2}>
                              <Img className={[styles.image, styles.imageRight].join(' ')} key={i} fluid={image.node.childImageSharp.fluid}></Img>
                          </div>
                      </Parallax>
            }
          )}
        </div>
      </motion.main>
    </ParallaxProvider>
  );
}

export default Index;


export const query = graphql`
    query {
      allimages: allFile( filter: { absolutePath: {regex: "./images/index/" } }) {
          edges {
            node {
              childImageSharp {
                  fluid (maxWidth: 600){
                      src
                      srcSet
                      aspectRatio
                      sizes
                      base64
                  }
            }
          }
          }
          }




    }`