import React from "react"
import * as styles from "./nav.module.scss"
import { Link } from "gatsby"

export default function ViewModule({}){
    return (
          <div className={[styles.bottom, styles.center, styles.nav].join(' ')}>
                <div className={[styles.center, styles.navMobile].join(' ')}>
                    <Link to="/people" className={styles.link}>people</Link>
                    <Link to="/corporate" className={styles.link}>corporate</Link>
                    <Link to="/lifestyle" className={styles.link}>lifestyle</Link>
                    <Link to="/about" className={styles.link}>about</Link>
                    <Link to="/imprint" className={styles.link}>imprint</Link>
                    <Link to="https://www.behance.net/gallery/137540241/lilschk-Visual-identity" target="_blank" className={styles.link}>case</Link>
                </div>
          </div>
    )
}