import React from "react"
import * as styles from "./logo.module.scss"


export default function ViewModule({}){
      return (
            <div className={[styles.fullwidth, styles.center].join(' ')}>
                  {/* <svg className={styles.svg} preserveAspectRatio="xMinYMin" overflow="visible" xmlns="http://www.w3.org/2000/svg">
                        <path className={styles.l} d="M140.5 120.7H120.5V230.7H140.5V120.7Z" fill="white"/>
                        <g className={styles.i}> <path d="M440.5 150.7H460.5V230.7H440.5V150.7Z" fill="white"/><path d="M440.5 120.7H460.5V140.8H440.5V120.7Z" fill="white"/></g>
                        <path className={styles.l2} d="M500.5 120.7H480.5V230.7H500.5V120.7Z" fill="white"/>
                        <path className={styles.s} d="M132.4 302.1C132.4 297.4 137.1 294.1 144 294.1C151.7 294.1 156.7 298.6 159.1 305.2L174.5 300C171.2 287.4 160.7 279.1 145.8 279.1C127.4 279.1 114.3 288.5 114.3 304.2C114.3 337.5 157.9 323.3 157.9 338.7C157.9 343.7 153 346.9 144.7 346.9C135 346.9 128.5 341.9 126 333.7L110.9 338.6C114 353.7 126.3 362.1 143.2 362.1C162.7 362.1 176.5 352.2 176.5 336.4C176.5 303.7 132.4 318.4 132.4 302.1Z" fill="white"/>
                        <path className={styles.c} d="M230.2 344.9C218.7 344.9 210.4 335 210.4 320.7C210.4 306.4 218.9 296.4 230.2 296.4C239.1 296.4 244.6 300.6 247.9 309.1L265.2 304.1C260.6 288.7 248.7 279.2 230.2 279.2C207 279.2 190.4 296.9 190.4 322.2C190.4 345.7 205.6 362.2 230.2 362.2C247.8 362.2 260.5 352.5 265.8 338.2L250 332.6C245.8 340.9 239.9 344.9 230.2 344.9Z" fill="white"/>
                        <path className={styles.h} d="M325.7 279.3C315 279.3 306.2 284 300.6 293.3V250.8H280.5V360.8H300.6V321.6C300.6 304.2 309.7 296.7 319.6 296.7C328.9 296.7 334.5 303.1 334.5 313.3V360.8H354.6V310.6C354.6 292.6 343.7 279.3 325.7 279.3Z" fill="white"/>
                        <path className={styles.k} d="M486.3 311.9L514.5 280.9H490.4L460.5 314.1V250.7H440.5V360.7H460.5V339.5L472.8 326.3L493.8 360.7H517L486.3 311.9Z" fill="white"/>
                  </svg>*/}

                  <svg width="407" height="243" viewBox="0 0 407 243" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className={styles.l} d="M30.5 0.699951H10.5V110.7H30.5V0.699951Z" fill="white"/>
                        <g className={styles.i}><path d="M330.5 0.699951H350.5V20.8H330.5V0.699951Z" fill="white"/><path d="M330.5 30.7H350.5V110.7H330.5V30.7Z" fill="white"/></g>
                        <path className={styles.l2} d="M390.5 0.699951H370.5V110.7H390.5V0.699951Z" fill="white"/>
                        <path className={styles.s} d="M22.3999 182.1C22.3999 177.4 27.0999 174.1 33.9999 174.1C41.6999 174.1 46.6999 178.6 49.0999 185.2L64.4999 180C61.1999 167.4 50.6999 159.1 35.7999 159.1C17.3999 159.1 4.2999 168.5 4.2999 184.2C4.2999 217.5 47.8999 203.3 47.8999 218.7C47.8999 223.7 42.9999 226.9 34.6999 226.9C24.9999 226.9 18.4999 221.9 15.9999 213.7L0.899902 218.6C3.9999 233.7 16.2999 242.1 33.1999 242.1C52.6999 242.1 66.4999 232.2 66.4999 216.4C66.4999 183.7 22.3999 198.4 22.3999 182.1Z" fill="white"/>
                        <path className={styles.c} d="M120.2 224.9C108.7 224.9 100.4 215 100.4 200.7C100.4 186.4 108.9 176.4 120.2 176.4C129.1 176.4 134.6 180.6 137.9 189.1L155.2 184.1C150.6 168.7 138.7 159.2 120.2 159.2C96.9999 159.2 80.3999 176.9 80.3999 202.2C80.3999 225.7 95.5999 242.2 120.2 242.2C137.8 242.2 150.5 232.5 155.8 218.2L140 212.6C135.8 220.9 129.9 224.9 120.2 224.9Z" fill="white"/>
                        <path className={styles.h} d="M215.7 159.3C205 159.3 196.2 164 190.6 173.3V130.8H170.5V240.8H190.6V201.6C190.6 184.2 199.7 176.7 209.6 176.7C218.9 176.7 224.5 183.1 224.5 193.3V240.8H244.6V190.6C244.6 172.6 233.7 159.3 215.7 159.3Z" fill="white"/>
                        <path className={styles.k} d="M376.3 191.9L404.5 160.9H380.4L350.5 194.1V130.7H330.5V240.7H350.5V219.5L362.8 206.3L383.8 240.7H407L376.3 191.9Z" fill="white"/>
                  </svg>

            </div>
      )
}